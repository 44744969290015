import styled from "styled-components";

export const Box = styled.div`

  padding: 0 1rem;
  
  h1 {
    margin: 1rem 0;
    color: #800;
  }
  h2{
    color:darkgray;
    margin-bottom: 10px;
  }


  img {
    width: 300px;
    border-radius: 1rem;
    margin-bottom: 2rem;
  }

  span {
    font-weight: normal;
    font-size: 120%;
    margin-bottom: 1rem;
    line-height: 130%;
  }

  button {
    background-color: #006;
    border: none;
    cursor: pointer;
    border-radius: 1rem;
    color: white;
    padding: 0.8rem 2rem;
    margin-top: 1rem;
    font-size: 100%;
    transition: all .3s;
  }

  button:hover {
    background-color: #009;
  }
  
  .release-date {
    font-size: 100%;
    opacity: 0.5;
  }

  .providers{
    
  }
  .providers img{
    width: 60px;
    margin: 30px;
    display: block; 
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 6rem;
    max-width: 50%;
  }

  .movie {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

`;