//import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { APIkey } from '../../../config/key';
import { Box } from './styles';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Details() {
  const { id } = useParams()
  const url = 'https://api.themoviedb.org/3/movie/'
  const movie_url = `${url}${id}?api_key=${APIkey}&language=pt-BR`
  const providers_url = `${url}${id}/watch/providers?api_key=${APIkey}&language=pt-BR`
  const [movie, setMovie] = useState([])
  const [providers, setProviders] = useState([])
  const imagePath = 'https://image.tmdb.org/t/p/w500/'

  useEffect(() => {
    fetch(movie_url)
    .then(response => response.json())
    .then(data => {
      //console.log(data);
      const {title, tagline, poster_path, release_date, overview} = data
      const movie = {
        id,
        title,
        tagline,
        image: `${imagePath}${poster_path}`,
        sinopse: overview,
        releaseDate: release_date
      }
      setMovie(movie)
    })

    fetch(providers_url)
    .then(response => response.json())
    .then(data => {
      if(data.results.BR.flatrate){
        setProviders(data.results.BR.flatrate);
        console.log(data.results.BR.flatrate);
      }
      
    })

  }, [id, movie_url, providers_url])

  return (
    <Box>
      <Container>
        <Row className="movie">
          <Col className="providers">
          {providers.map(provider => (  
              <img src={`${imagePath}${provider.logo_path}`} alt="provider" key={provider.provider_id}/>
            ))} 
          </Col>
          <Col>
            <img src={movie.image} alt={movie.sinopse}/>
          </Col>
          <Col className="details">
            <h1>{movie.title}</h1>
            <h2>{movie.tagline}</h2>
            <span>Sinopse: {movie.sinopse}</span>
            <span className='release-date'>Data de lançamento: {movie.releaseDate}</span>
            <Link to="/movies"><button>Voltar para lista</button></Link>
          </Col>
        </Row>
      </Container>
    </Box>
  );
}

export default Details;
