import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Movie, MovieList } from './styles';
import { APIkey } from '../config/key';

function Cards({genreId, searchText, type}){
    const imagePath = 'https://image.tmdb.org/t/p/w500/'
    const url=`https://api.themoviedb.org/3/discover/${type}?api_key=${APIkey}&language=pt-BR&with_genres=${genreId}`
    const popularUrl = `https://api.themoviedb.org/3/${type}/popular?api_key=${APIkey}&language=pt-BR`
    const searchUrl= `https://api.themoviedb.org/3/search/${type}?api_key=${APIkey}&query=${searchText}&language=pt-BR`
    const testUrl = `&sort_by=popularity.asc`
    const [movies, setMovies] = useState([]);

    useEffect(()=>{
            fetch(url)
           .then(response => response.json())
           .then(data => {
             console.log(data.results)
             setMovies(data.results)
           })
    },[genreId])


    return(
        <MovieList>

            {movies.map(movie => {
                return (
                <Movie key={movie.id}>
                    <Link to={`../${type}s/${movie.id}`}>
                    <img src={`${imagePath}${movie.poster_path}`} alt={movie.title}/>
                    </Link>
                    <span>{movie.title}</span>
                </Movie>
                )
            })}
      
      </MovieList>
    )
}

export default Cards