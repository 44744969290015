import { Box } from '../../components/styles';
import Cards from '../../components/Cards';
import Top from '../../components/Top';
import { useState } from 'react';


function Movies() {
  const [gen, setGen] = useState([])
  const [search, setSearch] = useState([])
  //console.log('GENERO_ID: '+gen)
  console.log('SEARCH: '+search)

  return (
    <Box>
      <Top type='movie' setGen={setGen} setSearch={setSearch}/>
      <Cards type='movie' genreId={gen} searchText={search}/>
    </Box>
  );
}

export default Movies;
