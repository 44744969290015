import styled from "styled-components";

export const Box = styled.div`
  padding: 1rem;
  text-align: center;
  h1 {
    text-align: center;
    margin: 1rem 0;
    color: #CCC;
  }
  a {
    text-decoration: none;
    color:#444;
  }
  a:hover{
    color:#800;
  }
`;

export const MovieList = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 1rem;
  row-gap: 2rem;
`;

export const Movie = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 180px;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }

  span {
    font-weight: bold;
    font-size: 100%;
    text-align: center;
    color: lightgray;
   
  }

  a {
    transition: all 0.3s;
  }

  a:hover {
    transform: scale(1.1);
  }
`
export const GenreList = styled.ul`
  list-style: none;
  width: 99%;
  column-gap: 0.1rem;
  row-gap: 0.1rem;
  display: grid;
  margin-bottom: 30px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
`;

export const Genre = styled.li`
  font-size: 90%;
  text-align: center;

  a {
    color: #777;
    text-decoration: none;
    transition: all 0.3s;
  }
  a:hover{
    color:#800;
    transform: scale(1.1);
  }
`
export const Selector = styled.button`
  color: darkgray;
  background-color: #333;
  width: 30%;
  height: fit-content;
  font-size: 150%;
  font-weight: normal;
  padding: 15px;
  margin: 10px;
  border-radius: 10px;
  :hover{
    color:#800;
    background-color:#500;
    transform:scale(1.05); 
    font-weight:bold;
    }
`
export const Button = styled.button`
  color: #444;
  background-color: #111;
  width: 150px;
  height: fit-content;
  font-weight: normal;
  padding: 5px;
  border-radius: 5px;
  :hover{
    color:lightgray;
    background-color:#300;
    transform:scale(1.05); 
    font-weight:bold;
    }
`

export const Search = styled.input`
  width: 30%;
`