import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Movies from './pages/Movies';
import Series from './pages/Series'
import MoviesDetails from './pages/Movies/Details';
import SeriesDetails from './pages/Series/Details';

import './global.css'
 

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Movies/>}/>
      <Route path="/movies" element={<Movies/>}/>
      <Route path="/tvs" element={<Series/>}/>
      <Route path="/movies/:id" element={<MoviesDetails/>}/>
      <Route path="/tvs/:id" element={<SeriesDetails/>}/>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
 
