import { GenreList, Genre, Selector, Button } from "./styles"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { APIkey } from "../config/key"

function Top({type, setGen, setSearch}){
    const [genres, setGenres] = useState([]);
    const activeStyle = {color:'#800', backgroundColor:'#400'};
    const inactiveStyle = {color:'#777'};
    const [active, setActive] = useState(false)
    const url = `https://api.themoviedb.org/3/genre/${type}/list?api_key=${APIkey}&language=pt-BR`



  useEffect(()=>{
    fetch(url)
    .then(response => response.json())
    .then(data => {
    console.log(data.genres)
    setGenres(data.genres)
  })
  },[])


    return(
        <>
        
          
        <Link to="../movies" onClick={(e)=>(setActive(false))}><Selector style={active ? inactiveStyle : activeStyle}>MOVIES</Selector></Link>
       
        <Link to="../tvs" onClick={(e)=>(setActive(true))}><Selector style={active ? activeStyle : inactiveStyle}>SERIES</Selector></Link>
        
        <GenreList>
        <Genre><Link to='#' onClick={(e)=>(setGen(''))}><Button>Populares</Button></Link></Genre>
        {genres.map(genre =>{
          return(
        
            <Genre key='genre.id'>
                <Link to='#' onClick={(e)=>(setGen(genre.id))}><Button>{genre.name}</Button></Link>
            </Genre>
          )
        })}
        
      </GenreList>
      </>
    )
}

export default Top